<div class="stores-container">
  <loader size="md" *ngIf="isLoading" [loading]="isLoading" class="loader"></loader>

  <div class="stores-container__top" [ngClass]="{ loading: isLoading }" *ngIf="isDuplicateAccount">
    <p class="heading2--extra-bold content-main-color">{{ 'STORES.CONNECT_HEADER' | translate }}</p>
    <div class="stores-container__top__header">
      <img
        class="stores-container__top__header__icon"
        loading="lazy"
        [src]="selectedCountry.flag"
      />
      <p class="caption1--medium content-medium-color">
        {{ 'STORES.CONNECT_DESCRIPTION' | translate : { selectedCountryName } }}
      </p>
    </div>
    <div class="duplicate-account">
      <img src="assets/img/product-details-icons/lock.svg" alt="" />
      {{ trans('wallet.duplicateAccountError') }}
    </div>
  </div>

  <div
    class="stores-container__top"
    [ngClass]="{ loading: isLoading }"
    *ngIf="!isDuplicateAccount && !isLoading"
  >
    <p class="heading2--extra-bold content-main-color">{{ 'STORES.CONNECT_HEADER' | translate }}</p>
    <div class="stores-container__top__header">
      <img
        class="stores-container__top__header__icon"
        loading="lazy"
        [src]="selectedCountry.flag"
      />
      <p class="caption1--medium content-medium-color">
        {{ 'STORES.CONNECT_DESCRIPTION' | translate : { selectedCountryName } }}
      </p>
    </div>

    <app-api-keys-integration *ngIf="isAPIKeysIntegrationEnabled"></app-api-keys-integration>

    <div class="stores-container__top__card-container">
      <div class="stores-container__top__filled-card store-card" *ngFor="let store of stores">
        <!-- Store Card -->
        <div class="same-row-dir">
          <img src="{{ store.storeImage }}" />
          <div class="stores-container__top__store-card__data">
            <div class="stores-container__top__filled-card__data__header">
              <p class="body1--bold content-main-color">
                {{ store.storeName | translate }}
              </p>

              <button
                *ngIf="currentCountryLinkedStore?.provider !== store.provider"
                [disabled]="currentCountryLinkedStore?.provider"
                (click)="onLinkStoreClicked(store)"
                class="stores-container__top__filled-card__data__header__btn body2--bold"
              >
                {{ 'STORES.CONNECT_STORE' | translate }}
              </button>

              <button
                *ngIf="currentCountryLinkedStore?.provider === store.provider"
                (mouseover)="onLinkStoreButtonHover()"
                (mouseout)="onLinkStoreButtonHoverAway()"
                (click)="onUnlinkStoreClicked(store)"
                class="stores-container__top__filled-card__data__header__btn-connected body2--bold"
              >
                {{ linkState | translate }}
              </button>
            </div>

            <p class="caption1--regular content-medium-color">
              {{ store.storeDescription | translate }}
            </p>
          </div>
        </div>

        <!-- How To Link Video  -->
        <button
          *ngIf="store?.howToLinkVideo"
          class="stores-container__top__filled-card__data__header__video-btn caption1--bold"
          (click)="openVideoDialog()"
        >
          <img loading="lazy" src="{{ assetsPath + 'dark-green-video-icon' }}.svg" />
          {{ 'STORES.HOW_TO_LINK' | translate }}
        </button>
      </div>

      <div class="stores-container__top__empty-card store-card">
        <p class="caption1--regular content-medium-color">{{ 'STORES.EMPTY' | translate }}</p>
      </div>
    </div>

    <ul *ngIf="currentCountryLinkedStore?.provider">
      <li class="caption1--regular content-medium-color">{{ 'STORES.ONE_STORE' | translate }}</li>
    </ul>
  </div>

  <div class="stores-container__bottom" *ngIf="userHasYoucan && !isDuplicateAccount">
    <mat-expansion-panel (click)="onHowToKnowClicked()">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <p class="stores-container__bottom__panel-header body2--bold">
            {{ 'STORES.HOW_CONNECT_STORE' | translate }}
          </p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="stores-container__bottom__steps">
        <button
          (click)="downloadYouCanImage()"
          class="stores-container__bottom__steps__download-btn"
        >
          <img loading="lazy" src="{{ assetsPath + 'download-icon' }}.svg" />
          <p class="body1--bold content-white-color">{{ 'STORES.DOWNLOAD_IMAGE' | translate }}</p>
        </button>
        <img
          class="stores-container__bottom__steps__download-img"
          loading="lazy"
          src="{{ youCanInfoImg }}"
        />
      </div>
    </mat-expansion-panel>
  </div>
  <div
    class="stores-container__dukan"
    *ngIf="isDukanEnabled && isUserEligibleToDukan && !isDuplicateAccount"
  >
    <p class="heading2--bold content-main-color">
      {{ 'STORES.DUKAN.HEADER.TEXT_1' | translate }}
      <span class="heading2--extra-bold content-success-color">{{
        'STORES.DUKAN.HEADER.TEXT_2' | translate
      }}</span>
    </p>
    <div class="stores-container__dukan__body">
      <div class="stores-container__dukan__body__container">
        <div class="stores-container__dukan__body__container__part">
          <section class="stores-container__dukan__body__right-part">
            <img loading="lazy" src="{{ assetsPath + 'online-dukan' }}.svg" />
            <div *ngIf="dukanShop; then dukanShopExists; else dukanShopDoesntExists"></div>
            <ng-template #dukanShopExists>
              <div [ngSwitch]="dukanShop.state">
                <div *ngSwitchCase="dukanStates.PENDING">
                  <p class="body2--bold content-main-color">
                    {{ 'STORES.DUKAN.BODY.TEXT_1' | translate }}
                  </p>
                  <p class="caption1--regular content-main-color">
                    {{ 'STORES.DUKAN.BODY.TEXT_2' | translate }}
                  </p>
                </div>
                <div *ngSwitchCase="dukanStates.ACTIVE || dukanStates.SUSPENDED">
                  <a class="body2--bold content-main-color" [href]="sanitizedUrl" target="_blank">
                    {{ dukanShop.url }}
                    <img loading="lazy" src="{{ assetsPath + 'link' }}.svg" />
                  </a>
                  <p class="caption1--regular content-main-color">
                    {{ 'STORES.DUKAN.DUKAN_CREATED' | translate }}
                  </p>
                </div>
                <div *ngSwitchCase="dukanStates.INACTIVE">
                  <a class="body2--bold content-main-color" [href]="sanitizedUrl" target="_blank">
                    {{ dukanShop.url }}
                    <img loading="lazy" src="{{ assetsPath + 'link' }}.svg" />
                  </a>
                  <p class="caption1--regular content-main-color">
                    {{ 'STORES.DUKAN.DUKAN_CREATED' | translate }}
                  </p>
                </div>
              </div>
            </ng-template>
            <ng-template #dukanShopDoesntExists>
              <div>
                <p class="body2--bold content-main-color">
                  {{ 'STORES.DUKAN.BODY.TEXT_1' | translate }}
                </p>
                <p class="caption1--regular content-main-color">
                  {{ 'STORES.DUKAN.BODY.TEXT_2' | translate }}
                </p>
              </div>
            </ng-template>
          </section>
          <section class="stores-container__dukan__body__left-part">
            <div *ngIf="dukanShop; then editShopCTA; else createShopCTA"></div>
            <ng-template #editShopCTA>
              <div [ngSwitch]="dukanShop.state">
                <div *ngSwitchCase="dukanStates.ACTIVE">
                  <button
                    class="stores-container__dukan__body__edit-btn dukan-buttons caption1--medium"
                    [routerLink]="appURLs.EDIT_DUKAN_URL"
                  >
                    {{ 'STORES.DUKAN.EDIT' | translate }}
                  </button>
                </div>
                <div *ngSwitchCase="dukanStates.INACTIVE">
                  <button
                    (click)="onReactivateClicked()"
                    class="stores-container__dukan__body__create-btn dukan-buttons caption1--medium"
                  >
                    {{ 'STORES.DUKAN.REACTIVATE' | translate }}
                  </button>
                </div>
              </div>
            </ng-template>
            <ng-template #createShopCTA
              ><button
                (click)="setupDukanDialog()"
                class="stores-container__dukan__body__create-btn dukan-buttons caption1--medium"
              >
                {{ 'STORES.DUKAN.CREATE' | translate }}
              </button>
            </ng-template>
          </section>
        </div>
        <ng-container *ngIf="dukanShop">
          <div
            class="stores-container__dukan__body__pending"
            *ngIf="dukanShop.state === dukanStates.PENDING"
          >
            <div>
              <p class="body2--bold content-main-color">
                {{ 'STORES.DUKAN.PREPARE_DUKAN' | translate }}
                <span class="body2--bold content-main-color">"{{ dukanShop.slug }}"</span>
              </p>
            </div>
            <div class="stores-container__dukan__body__pending__container">
              <div
                *ngFor="let step of dukanPendingSteps"
                class="stores-container__dukan__body__pending__statuses"
              >
                <img src="{{ step.img }}" />
                <div>
                  <p class="caption1--bold content-main-color">{{ step.state | translate }}</p>
                  <p class="caption2--medium content-medium-color">{{ step.status | translate }}</p>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
