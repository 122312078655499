import { NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { country } from '@features/country/data';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { getMerchantIdVerification } from '@presentation/kyc/atoms/utils/get-verification-object';
import { queryString } from '@presentation/shared/router';
import { DukanShopModel, StateStatuses } from 'app/core/domain/dukan-shop/dukan-shop.model';
import { StoreModel } from 'app/core/domain/store.model';
import { UserStoreModel } from 'app/core/domain/user-store.model';
import { LogMixpanelEventUseCase } from 'app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetDukanShopUseCase } from 'app/core/usecases/dukan-shop/get-dukan.usecase';
import { ReactivateDukanShopUseCase } from 'app/core/usecases/dukan-shop/reactivate-dukan.usecase';
import { GetFeatureFlagUsecase } from 'app/core/usecases/get-feature-flag.usecase';
import { GetStoresUseCase } from 'app/core/usecases/merchant-store/get-stores-usecase';
import { GetUserStoresUseCase } from 'app/core/usecases/merchant-store/get-user-stores-usecase';
import { LinkMerchantStoreUseCase } from 'app/core/usecases/merchant-store/link-merchant-store.usecase';
import { CheckUserFeatureExistsUseCase } from 'app/core/usecases/user/check-user-feature-exists.usecase';
import { appUrlsConstantsInjectionToken } from 'app/data/injection-tokens/app-urls-constants.injection-token';
import { getStoreRedirectUri } from 'app/data/repositories/merchant-store/utilities/get-store-redirect-uri.utility';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { LoaderComponent } from 'app/presentation/shared/components/loader/loader.component';
import {
  FEATURE_FLAGS,
  WOOCOMMERCE_PROVIDER_NAME,
  YOUCAN_PROVIDER_NAME,
  YOUCAN_USER_FEATURE,
  ZID_PROVIDER_NAME,
} from 'app/presentation/shared/constants';
import { SiteTranslateService } from 'app/presentation/shared/services/translate.service';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, finalize } from 'rxjs';
import { ApiKeysIntegrationComponent } from '../api-keys-integration/api-keys-integration.component';
import { DukanSuccessfullyCreatedComponent } from '../dukan/dukan-successfully-created/dukan-successfully-created.component';
import { SetupYourDukanComponent } from '../dukan/setup-your-dukan/setup-your-dukan.component';
import { StoreLinkedSuccessfullyComponent } from '../dukan/store-linked-successfully/store-linked-successfully.component';
import { StoreLinkingErrorComponent } from '../dukan/store-linking-error/store-linking-error.component';
import { UnlinkStorePopUpComponent } from '../dukan/unlink-store-pop-up/unlink-store-pop-up.component';
import { YoucanCheckoutPopUpComponent } from '../dukan/youcan-checkout-pop-up/youcan-checkout-pop-up.component';
import { WooCommerceFailureModalComponent } from '../woo-commerce-failure-modal/woo-commerce-failure-modal.component';
import { WooCommerceModalComponent } from '../woo-commerce-modal/woo-commerce-modal.component';
import { WooCommerceSuccessModalComponent } from '../woo-commerce-success-modal/woo-commerce-success-modal.component';
import { YoucanLinkVideoComponent } from '../youcan-link-video/youcan-link-video.component';

@Component({
  selector: 'app-old-stores',
  templateUrl: './old-stores.component.html',
  styleUrls: ['./old-stores.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    LoaderComponent,
    NgClass,
    NgFor,
    MatExpansionModule,
    NgSwitch,
    NgSwitchCase,
    RouterLink,
    TranslateModule,
    MatDialogModule,
    ApiKeysIntegrationComponent,
  ],
})
export class OldStoresComponent extends LocalizedComponent implements OnInit {
  public selectedCountry = country;

  public selectedCountryName: string;

  public assetsPath = 'assets/img/';

  public stores: StoreModel[];

  public isLoading = true;

  public linkState = 'STORES.LINKED';

  public currentCountryLinkedStore: UserStoreModel;

  public isDukanEnabled = false;

  public dukanShop: DukanShopModel;

  public dukanStates = StateStatuses;

  public sanitizedUrl: SafeUrl;

  public userHasYoucan = false;

  public isUserEligibleToDukan = false;

  public isAPIKeysIntegrationEnabled = false;

  public youCanInfoImg: string;

  public youCanInfoArImg =
    'https://taager-public-media.s3.eu-west-1.amazonaws.com/learning-center/youcan-Information-ar.svg';

  public youCanInfoEnImg =
    'https://taager-public-media.s3.eu-west-1.amazonaws.com/learning-center/youcan-Information-en.svg';

  public dukanPendingSteps = [
    {
      state: 'STORES.DUKAN.PENDING.ACCEPTING',
      status: 'STORES.DUKAN.PENDING.DONE',
      img: `${this.assetsPath}done.svg`,
    },
    {
      state: 'STORES.DUKAN.PENDING.PREPARING',
      status: 'STORES.DUKAN.PENDING.DONE',
      img: `${this.assetsPath}done.svg`,
    },
    {
      state: 'STORES.DUKAN.PENDING.CHECKING',
      status: 'STORES.DUKAN.PENDING.IN_PROGRESS',
      img: `${this.assetsPath}pending.svg`,
    },
  ];

  public isDuplicateAccount = false;

  constructor(
    private _siteTranslateService: SiteTranslateService,
    private _translateService: TranslateService,
    private _getStoresUseCase: GetStoresUseCase,
    private _activatedRoute: ActivatedRoute,
    private _linkMerchantStoreUseCase: LinkMerchantStoreUseCase,
    private _dialog: MatDialog,
    private _modal: MatDialog,
    private _getUserStoresUseCase: GetUserStoresUseCase,
    private _toastr: ToastrService,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _getFeatureFlagUsecase: GetFeatureFlagUsecase,
    private _getShopUseCase: GetDukanShopUseCase,
    private _reactivateShopUseCase: ReactivateDukanShopUseCase,
    private _sanitizer: DomSanitizer,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,

    @Inject(appUrlsConstantsInjectionToken) public appURLs: { [url: string]: string },
  ) {
    super();
  }

  ngOnInit(): void {
    this.onLocaleChange(() => {
      this._setImageNameAccordingToLanguage();
    });
    this._setImageNameAccordingToLanguage();
    this.getStores();
    this._getQueryParams();
    this.getUserStores();
    this.getDukanShop();
    this.checkAPIKeysIntegration();
    this.checkMerchantIdVerification();

    this.displayWooCommerceIntegrationState();
  }

  displayWooCommerceIntegrationState(): void {
    const success = queryString.get('success');

    if (!success) return;

    if (success === '1') {
      this.openSuccessWooCommerceModal();
    } else if (success === '0') {
      this.openFailureWooCommerceModal();
    }
  }

  protected checkMerchantIdVerification(): void {
    getMerchantIdVerification(
      this._getFeatureFlagUsecase,
      this._checkUserFeatureExistsUseCase,
    ).then((response) => {
      this.isDuplicateAccount = response.isDuplicate;
      this.isLoading = false;
    });
  }

  getStores(): void {
    this._getStoresUseCase.execute().subscribe({
      next: (stores) => {
        combineLatest({
          youcanFlag: this._getFeatureFlagUsecase.execute(FEATURE_FLAGS.YOUCAN_STORE),
          zidFlag: this._getFeatureFlagUsecase.execute(FEATURE_FLAGS.ZID_INTEGRATION),
          dukanFlag: this._getFeatureFlagUsecase.execute(FEATURE_FLAGS.IS_DUKAN_ENABLED),
          wooCommerceFlag: this._getFeatureFlagUsecase.execute(FEATURE_FLAGS.WOOCOMMERCE_STORE),
        }).subscribe({
          next: ({ youcanFlag, zidFlag, dukanFlag, wooCommerceFlag }) => {
            const selectedCountryIsoCode3 = country.code.toLowerCase();
            this.userHasYoucan = this._checkUserFeatureExistsUseCase.execute(
              `${YOUCAN_USER_FEATURE}${selectedCountryIsoCode3}`,
            );
            this.stores = stores.filter(
              (store) =>
                (youcanFlag && store.provider === YOUCAN_PROVIDER_NAME && this.userHasYoucan) ||
                (zidFlag && store.provider === ZID_PROVIDER_NAME && country.is('KSA')) ||
                (wooCommerceFlag && store.provider === WOOCOMMERCE_PROVIDER_NAME),
            );

            this.isDukanEnabled = dukanFlag;
          },
        });
      },
    });
  }

  checkAPIKeysIntegration(): void {
    this._getFeatureFlagUsecase.execute(FEATURE_FLAGS.API_KEYS_INTEGRATION).subscribe((flag) => {
      this.isAPIKeysIntegrationEnabled = flag;
    });
  }

  getUserStores(): void {
    this._getUserStoresUseCase.execute().subscribe({
      next: (stores) => {
        const selectedCountry = country.code;
        this.currentCountryLinkedStore = stores.find((store) => store.market === selectedCountry)!;
      },
    });
  }

  _setSelectedCountryBasedOnLanguage(): void {
    this.selectedCountryName = country.name;
  }

  onLinkStoreClicked(store: StoreModel): void {
    if (store.provider === YOUCAN_PROVIDER_NAME) {
      const dialogRef = this._dialog.open(YoucanCheckoutPopUpComponent, {
        width: '900px',
        panelClass: 'linked-store-dialog',
        direction: this._siteTranslateService.currentDir,
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res === true) {
          window.location.href = getStoreRedirectUri(store.provider)!;
        }
      });
    }

    if (store.provider === WOOCOMMERCE_PROVIDER_NAME) {
      const insertLinkModal = this._dialog.open(WooCommerceModalComponent, {
        direction: this._siteTranslateService.currentDir,
        panelClass: 'linked-store-dialog',
        width: '524px',
      });

      insertLinkModal.afterClosed().subscribe((res) => {
        if (res === true) {
          window.location.href = getStoreRedirectUri(store.provider)!;
        }
      });
    } else {
      window.location.href = getStoreRedirectUri(store.provider)!;
    }
  }

  openSuccessWooCommerceModal(): void {
    this._modal.open(WooCommerceSuccessModalComponent, {
      direction: this._siteTranslateService.currentDir,
      width: '485px',
    });
  }

  openFailureWooCommerceModal(): void {
    this._modal.open(WooCommerceFailureModalComponent, {
      direction: this._siteTranslateService.currentDir,
      width: '485px',
    });
  }

  onLinkStoreButtonHover(): void {
    this.linkState = 'STORES.UNLINK';
  }

  onLinkStoreButtonHoverAway(): void {
    this.linkState = 'STORES.LINKED';
  }

  onUnlinkStoreClicked(store: StoreModel): void {
    const dialogRef = this._dialog.open(UnlinkStorePopUpComponent, {
      width: '450px',
      panelClass: 'linked-store-dialog',
      direction: this._siteTranslateService.currentDir,
      data: {
        provider: store.provider,
        storeId: this.currentCountryLinkedStore?.storeId,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: () => {
        this.getUserStores();
      },
    });
    this._logMixpanelEventUseCase.execute({
      eventName: 'click_unlink_store_button',
      payload: { provider: store.provider, storeId: this.currentCountryLinkedStore?.storeId },
    });
  }

  setupDukanDialog(): void {
    const dialogRef = this._dialog.open(SetupYourDukanComponent, {
      width: '700px',
      panelClass: 'linked-store-dialog',
      direction: this._siteTranslateService.currentDir,
    });
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        this.dukanShop = res;
        this.sanitizedUrl = this._sanitizer.bypassSecurityTrustUrl(this.dukanShop?.url);
        if (res?.state === StateStatuses.ACTIVE) {
          this._dialog.open(DukanSuccessfullyCreatedComponent, {
            width: '700px',
            panelClass: 'linked-store-dialog',
            direction: this._siteTranslateService.currentDir,
            data: {
              url: res.url,
              reactivated: false,
            },
          });
        }
      },
    });
  }

  getDukanShop(): void {
    this._getShopUseCase.execute().subscribe({
      next: (res) => {
        this.isUserEligibleToDukan = true;
        this.dukanShop = res;
        this.sanitizedUrl = this._sanitizer.bypassSecurityTrustUrl(this.dukanShop.url);
      },
      error: (err) => {
        if (err.status === 404) {
          this.isUserEligibleToDukan = true;
        } else {
          this.isUserEligibleToDukan = false;
        }
      },
    });
  }

  private _getQueryParams(): void {
    this._activatedRoute.queryParams.subscribe({
      next: (params) => {
        if (params.code) {
          this.isLoading = true;
          if (params.provider === ZID_PROVIDER_NAME && params.code === 'undefined') {
            window.location.href = getStoreRedirectUri(ZID_PROVIDER_NAME)!;
          }
          const linkedStore = this.stores.find((store) => store.provider === params.provider);
          this._logMixpanelEventUseCase.execute({
            eventName: 'click_link_store_button',
            payload: { provider: params.provider },
          });
          const location = `https://${window.location.hostname}`;
          this._linkMerchantStoreUseCase
            .execute({
              authCode: params.code,
              provider: params.provider,
              redirectUri: location + linkedStore!.redirectUri,
            })
            .pipe(
              finalize(() => {
                this.isLoading = false;
              }),
            )
            .subscribe({
              next: (res) => {
                this.getUserStores();
                this._logMixpanelEventUseCase.execute({
                  eventName: 'click_link_store_successful_message',
                  payload: {
                    provider: params.provider,
                    storeId: this.currentCountryLinkedStore?.storeId,
                  },
                });
                this._dialog.open(StoreLinkedSuccessfullyComponent, {
                  width: '450px',
                  panelClass: 'linked-store-dialog',
                  direction: this._siteTranslateService.currentDir,
                });
              },
              error: (err) => {
                this._logMixpanelEventUseCase.execute({
                  eventName: 'click_link_store_failure_message',
                  payload: { error: err.error.message, provider: params.provider },
                });
                this._dialog.open(StoreLinkingErrorComponent, {
                  width: '450px',
                  panelClass: 'linked-store-dialog',
                  data: {
                    provider: params.provider,
                  },
                });
              },
            });
        }
      },
    });
  }

  onHowToKnowClicked(): void {
    this._logMixpanelEventUseCase.execute({ eventName: 'click_how_to_link' });
  }

  onReactivateClicked(): void {
    this._reactivateShopUseCase.execute().subscribe({
      next: (store) => {
        this.dukanShop = store;
        this._dialog.open(DukanSuccessfullyCreatedComponent, {
          width: '700px',
          panelClass: 'linked-store-dialog',
          direction: this._siteTranslateService.currentDir,
          data: {
            url: store.url,
            reactivated: true,
          },
        });
      },
      error: () => {
        this._toastr.error(this._translateService.instant('STORES.DUKAN.ERRORS.REACTIVATE'));
      },
    });
  }

  downloadYouCanImage(): void {
    const image = this.isLTR ? this.youCanInfoEnImg : this.youCanInfoArImg;
    const link = document.createElement('a');
    link.href = image;
    link.target = '_blank';
    link.download = 'youcan-info.svg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private _setImageNameAccordingToLanguage(): void {
    this.youCanInfoImg = this.isLTR ? this.youCanInfoEnImg : this.youCanInfoArImg;
  }

  public openVideoDialog(): void {
    this._dialog.open(YoucanLinkVideoComponent, {
      width: '800px',
    });
    this._logMixpanelEventUseCase.execute({ eventName: 'you_can_video_button_clicked' });
  }
}
