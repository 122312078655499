import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GetDukanShopUseCase } from 'app/core/usecases/dukan-shop/get-dukan.usecase';
import { GetFeatureFlagUsecase } from 'app/core/usecases/get-feature-flag.usecase';
import { GetStoresUseCase } from 'app/core/usecases/merchant-store/get-stores-usecase';
import { BasePresenter } from 'app/presentation/base/base.presenter';
import {
  DUKAN_PROVIDER_NAME,
  EASY_ORDERS_PROVIDER_NAME,
  FEATURE_FLAGS,
} from 'app/presentation/shared/constants';
import { country } from '../../../../v3/features/country/data';
import { DukanShopModel } from '../../../core/domain/dukan-shop/dukan-shop.model';
import { GetUserStoresUseCase } from '../../../core/usecases/merchant-store/get-user-stores-usecase';
import { StoresSideEffect } from './stores.side-effects';
import { StoresViewEvents } from './stores.view-events';
import { StoresViewState } from './stores.view-state';

@Injectable()
export class StoresPresenter extends BasePresenter<
  StoresViewState,
  StoresViewEvents,
  StoresSideEffect
> {
  questions = [
    {
      title: 'STORES.QUESTIONS.QUESTION_1_TITLE',
      content: 'STORES.QUESTIONS.QUESTION_1_CONTENT',
    },
    {
      title: 'STORES.QUESTIONS.QUESTION_2_TITLE',
      content: 'STORES.QUESTIONS.QUESTION_2_CONTENT',
    },
  ];

  courses = {
    easy_orders: {
      title: 'STORES.EASY_ORDERS.COURSE_TITLE',
      course_content: [
        {
          course_title: 'STORES.EASY_ORDERS.FIRST_COURSE',
          course_link: 'https://taager.com/sa/learning-center/easy-orders1',
        },
        {
          course_title: 'STORES.EASY_ORDERS.SECOND_COURSE',
          course_link: 'https://taager.com/sa/learning-center/easy-orders2',
        },
        {
          course_title: 'STORES.EASY_ORDERS.THIRD_COURSE',
          course_link: 'https://taager.com/sa/learning-center/easy-orders3',
        },
        {
          course_title: 'STORES.EASY_ORDERS.FOURTH_COURSE',
          course_link: 'https://taager.com/sa/learning-center/easy-orders4',
        },
      ],
    },
    you_can: {
      title: 'STORES.YOUCAN.COURSE_TITLE',
      course_content: [
        {
          course_title: 'STORES.YOUCAN.FIRST_COURSE',
          course_link: 'https://taager.com/sa/learning-center/youcan1',
        },
        {
          course_title: 'STORES.YOUCAN.SECOND_COURSE',
          course_link: 'https://taager.com/sa/learning-center/youcan2 ',
        },
      ],
    },
    woo_commerce: {
      title: 'STORES.WOOCOMMERCE.COURSE_TITLE',
      course_content: [
        {
          course_title: 'STORES.WOOCOMMERCE.FIRST_COURSE',
          course_link: 'https://taager.com/sa/learning-center/taager-wordpress-plugin1',
        },
        {
          course_title: 'STORES.WOOCOMMERCE.SECOND_COURSE',
          course_link: 'https://taager.com/sa/learning-center/taager-wordpress-plugin2',
        },
        {
          course_title: 'STORES.WOOCOMMERCE.THIRD_COURSE',
          course_link: 'https://taager.com/sa/learning-center/taager-wordpress-plugin3',
        },
        {
          course_title: 'STORES.WOOCOMMERCE.FOURTH_COURSE',
          course_link: 'https://taager.com/sa/learning-center/taager-wordpress-plugin4',
        },
        {
          course_title: 'STORES.WOOCOMMERCE.FIFTH_COURSE',
          course_link: 'https://taager.com/sa/learning-center/taager-wordpress-plugin5',
        },
        {
          course_title: 'STORES.WOOCOMMERCE.SIXTH_COURSE',
          course_link: 'https://taager.com/sa/learning-center/taager-wordpress-plugin6',
        },
        {
          course_title: 'STORES.WOOCOMMERCE.SEVENTH_COURSE',
          course_link: 'https://taager.com/sa/learning-center/taager-wordpress-plugin7',
        },
        {
          course_title: 'STORES.WOOCOMMERCE.EIGHTH_COURSE',
          course_link: 'https://taager.com/sa/learning-center/taager-wordpress-plugin8',
        },
        {
          course_title: 'STORES.WOOCOMMERCE.NINTH_COURSE',
          course_link: 'https://taager.com/sa/learning-center/taager-wordpress-plugin9',
        },
        {
          course_title: 'STORES.WOOCOMMERCE.TENTH_COURSE',
          course_link: 'https://taager.com/sa/learning-center/taager-wordpress-plugin10',
        },
        {
          course_title: 'STORES.WOOCOMMERCE.ELEVENTH_COURSE',
          course_link: 'https://taager.com/sa/learning-center/taager-wordpress-plugin11',
        },
      ],
    },
    zid: {
      title: 'STORES.ZID.COURSE_TITLE',
      course_content: [
        {
          course_title: 'STORES.ZID.FIRST_COURSE',
          course_link: 'https://taager.com/sa/learning-center/taager-zid-plugin1',
        },
      ],
    },
  };

  public dukanShop: DukanShopModel;

  constructor(
    private _getStoresUseCase: GetStoresUseCase,
    private _getShopUseCase: GetDukanShopUseCase,
    private _getFeatureFlagUsecase: GetFeatureFlagUsecase,
    private _getUserStoresUseCase: GetUserStoresUseCase,
    private _sanitizer: DomSanitizer,
  ) {
    super();
  }

  protected defaultViewState(): StoresViewState {
    return {
      revampedStoresEnabled: false,
      isAPIKeysIntegrationEnabled: false,
      userStores: [],
      isUserEligibleToDukan: false,
      selectedStore: { name: '', icon: '', provider: '', redirectUri: '', supportedCountries: [] },
      currentStoreCourse: {
        title: '',
        course_content: [],
      },
      countryName: '',
      isLoading: false,
      isDuplicateAccount: false,
    };
  }

  protected onViewEvent(event: StoresViewEvents): void {
    switch (event.type) {
      case 'Init': {
        this.checkAPIKeysIntegration();
        this.getStores();
        this.getDukanStore();
        this.getUserStores();
        break;
      }
      case 'SelectionChanged': {
        this.merge({
          selectedStore: event.store,
          currentStoreCourse: this.getSelectedStoreCourses(event.store),
        });

        break;
      }
      case 'StartStoreIntegration': {
        break;
      }
    }
  }

  getUserStores(): void {
    this._getUserStoresUseCase.execute().subscribe({
      next: (stores) => {
        const selectedCountry = country.code;
        const currentCountryLinkedStore = stores.find((store) => store.market === selectedCountry)!;

        if (currentCountryLinkedStore) {
          this.merge({
            currentCountryLinkedStore,
          });
        }
      },
    });
  }

  getStores(): void {
    this._getStoresUseCase.execute().subscribe({
      next: (stores) => {
        this.merge({
          userStores: [
            ...this.viewState.userStores,
            ...stores
              .filter((store) => store.supportedCountries.includes(country.code))
              .map((store) => {
                return {
                  name: store.storeName,
                  icon: store.storeImage,
                  provider: store.provider,
                  redirectUri: store.redirectUri,
                  howToLinkVideo: store.howToLinkVideo,
                  supportedCountries: store.supportedCountries,
                };
              }),
          ],
          selectedStore: this.viewState.userStores[0],
          currentStoreCourse: this.getSelectedStoreCourses(),
        });
      },
    });
  }

  getSelectedStoreCourses(store = this.viewState.selectedStore): {
    title: string;
    course_content: { course_title: string; course_link: string }[];
  } {
    switch (store.name) {
      case 'STORES.WOOCOMMERCE.NAME':
        return this.courses.woo_commerce;
      case 'STORES.YOUCAN.NAME':
        return this.courses.you_can;
      case 'STORES.EASY_ORDERS.NAME':
        return this.courses.easy_orders;
      case 'STORES.ZID.NAME':
        return this.courses.zid;
      default:
        return {
          title: '',
          course_content: [],
        };
    }
  }

  getDukanStore(): void {
    const supportedCountries = ['EGY', 'ARE'];
    if (!supportedCountries.includes(country.code)) return;

    const dukanStore = {
      name: 'STORES.DUKAN.NAME',
      icon: 'https://storage.googleapis.com/taager-public-media/learning/dukan.svg',
      provider: DUKAN_PROVIDER_NAME,
      redirectUri: '',
      supportedCountries: ['EGY', 'ARE'],
    };

    this._getShopUseCase.execute().subscribe({
      next: (res) => {
        this.merge({
          isUserEligibleToDukan: true,
          currentCountryLinkedStore: {
            market: country.code,
            provider: DUKAN_PROVIDER_NAME,
            storeId: this._sanitizer.bypassSecurityTrustUrl(res.url) as string,
          },
          userStores: [...this.viewState.userStores, dukanStore],
          selectedStore: this.viewState.userStores[0]?.provider
            ? this.viewState.userStores[0]
            : dukanStore,
          currentStoreCourse: this.getSelectedStoreCourses(this.viewState.userStores[0] || []),
        });
      },
      error: (err) => {
        const isUserEligibleToDukan = err.status === 404;
        const userStores = [...this.viewState.userStores];

        if (isUserEligibleToDukan) {
          userStores.push(dukanStore);
        }

        this.merge({
          isUserEligibleToDukan,
          userStores,
          selectedStore: this.viewState.userStores[0]?.provider
            ? this.viewState.userStores[0]
            : dukanStore,
        });
      },
    });
  }

  checkAPIKeysIntegration(): void {
    const supportedCountries = ['EGY', 'SAU'];
    if (!supportedCountries.includes(country.code)) return;

    this._getFeatureFlagUsecase.execute(FEATURE_FLAGS.API_KEYS_INTEGRATION).subscribe((flag) => {
      if (flag) {
        const easyOrdersStore = {
          name: 'STORES.EASY_ORDERS.NAME',
          icon: 'https://storage.googleapis.com/taager-public-media/learning/easy-orders.svg',
          provider: EASY_ORDERS_PROVIDER_NAME,
          redirectUri: '',
          howToLinkVideo: 'https://www.youtube.com/embed/Sc8xaCReSYI?si=Ot9NTLCkUiupYmIr',
          supportedCountries: ['EGY', 'SAU'],
        };

        this.merge({
          userStores: [...this.viewState.userStores, easyOrdersStore],
          isAPIKeysIntegrationEnabled: flag,
        });
      }
    });
  }
}
